import { createAction, props } from '@ngrx/store';
import { Facets } from '@wam/shared';

export const updateFacets = createAction(
  '[Filtering API] Update Facets',
  props<{ facets: Facets }>(),
);

export const updateSelected = createAction(
  '[Filtering Sidebar] Update Selected',
  props<{ selected: Facets }>(),
);

export const clearFilters = createAction('[Activities Component] Clear Filters');
