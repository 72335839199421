import { createReducer, on } from '@ngrx/store';

import * as filteringActions from './filtering.actions';
import { FilteringState } from '@wam/shared';

const initialState: FilteringState = {
  facets: {},
  selected: {},
};

const filteringReducer = createReducer(
  initialState,
  on(filteringActions.updateFacets, (state, { facets }) => ({
    ...state,
    facets,
  })),
  on(filteringActions.updateSelected, (state, { selected }) => ({
    ...state,
    selected,
  })),
  on(filteringActions.clearFilters, (state) => ({
    ...state,
    facets: {},
    selected: {},
  })),
);

export function reducer(state, action) {
  return filteringReducer(state, action);
}
